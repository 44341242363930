// extracted by mini-css-extract-plugin
export var wrap = "News-module--wrap--3kdvJ";
export var link = "News-module--link--3QPMn";
export var linkreg = "News-module--linkreg--3Koj8";
export var svg = "News-module--svg--3jphk";
export var text = "News-module--text--3XuMW";
export var wrapAnchor = "News-module--wrapAnchor--3ZfMA";
export var commentAdd = "News-module--commentAdd--9vSx0";
export var commentBtn = "News-module--commentBtn--1Mf3q";
export var backContainer = "News-module--backContainer--cYLGP";
export var iconWrap = "News-module--iconWrap--2f6LC";