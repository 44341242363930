import React from "react";

function AnchorSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width="30"
      height="30"
      enableBackground="new 0 0 408.759 408.759"
      version="1.1"
      viewBox="0 0 408.759 408.759"
      xmlSpace="preserve"
    >
      <path
        fill="#FFBE00"
        d="M204.384 408.759c-58.121 0-113.674-24.869-152.417-68.227C18.459 303.057.004 254.703.004 204.378.004 91.683 91.689 0 204.384 0c50.317 0 98.674 18.453 136.165 51.964 43.346 38.761 68.205 94.314 68.205 152.415.001 112.695-91.679 204.38-204.37 204.38"
      ></path>
      <path
        fill="#F4502A"
        d="M377.752 204.38c0 18.081-2.77 35.522-7.911 51.901-20.391 65.09-78.11 113.66-148.079 120.61-5.721.58-11.51.87-17.38.87-3.551 0-7.071-.111-10.561-.321-47.13-2.829-89.19-24.488-118.74-57.559-.26-.29-.521-.589-.79-.879C47.34 288.44 30.999 248.321 30.999 204.38c0-95.749 77.621-173.379 173.381-173.379 43.95 0 84.069 16.35 114.629 43.291.29.27.591.53.881.79 33.06 29.57 54.71 71.599 57.539 118.731.212 3.499.323 7.018.323 10.567"
      ></path>
      <path
        fill="#C29100"
        d="M336.32 91.898l.142.167c-.049-.058-.094-.109-.142-.167m-.4-.466l.245.286c-.082-.096-.162-.191-.245-.286m-16.645-16.899c.206.185.414.367.616.549a174.692 174.692 0 01-.616-.549m-22.757-17.054l.198.125-.198-.125m-.466-.291l.351.219a33.624 33.624 0 00-.351-.219m-91.668-26.189c-66.957 0-125.049 37.955-153.935 93.516 28.887-55.563 86.974-93.514 153.931-93.514 33.641 0 65.035 9.578 91.618 26.154-26.577-16.578-57.976-26.156-91.614-26.156"
      ></path>
      <path
        fill="#BA3D20"
        d="M204.382 31.001c-66.957 0-125.045 37.951-153.931 93.514-12.426 23.901-19.445 51.054-19.445 79.854 0 53.1 23.858 100.621 61.459 132.419-25.53-30.2-40.919-69.239-40.919-111.88 0-95.751 77.619-173.37 173.38-173.37 42.629 0 81.68 15.39 111.87 40.919l-.334-.394a21.025 21.025 0 00-.142-.167l-.154-.181-.245-.286-.043-.05a174.817 174.817 0 00-15.986-16.3c-.203-.182-.41-.364-.616-.549a173.891 173.891 0 00-22.559-16.929l-.198-.125-.116-.073-.351-.219a1.498 1.498 0 01-.052-.033c-26.583-16.572-57.979-26.15-91.618-26.15"
      ></path>
      <path
        fill="#BA3D20"
        d="M167.02 201.44l-38.89 38.892c-11.12 11.109-11.12 29.189 0 40.299 5.556 5.556 12.853 8.333 20.15 8.333s14.595-2.778 20.15-8.333l32.65-32.65 6.24-6.24a27.575 27.575 0 010 0 50.211 50.211 0 01-26.39-13.911 50.224 50.224 0 01-13.91-26.39m25.125-13.284h-.02c-1.245.001-2.49.083-3.726.246-1.119 8.479 1.581 17.369 8.09 23.869 5.548 5.548 12.828 8.327 20.115 8.327 1.251 0 2.503-.082 3.746-.246.166-1.259.248-2.526.246-3.793-.012-7.271-2.792-14.532-8.327-20.067l-.135-.134a31.582 31.582 0 00-.186-.182c-5.484-5.323-12.597-7.998-19.717-8.02h-.086m68.335-68.367c-7.297 0-14.595 2.778-20.149 8.333l-38.901 38.899a50.203 50.203 0 0126.401 13.911 50.226 50.226 0 0113.909 26.39l38.89-38.89c11.111-11.111 11.111-29.191 0-40.31-5.554-5.555-12.852-8.333-20.15-8.333m35.71-7.217c19.691 19.69 19.691 51.729 0 71.42l-43.839 43.839a50.138 50.138 0 01-14.43 10.09l-.443.203c.148-.066.296-.134.443-.203a50.168 50.168 0 01-10.09 14.431l-43.841 43.839c-9.845 9.845-22.778 14.768-35.71 14.768s-25.865-4.923-35.71-14.768l81.25 81.25c3.49.21 7.01.321 10.561.321 5.87 0 11.66-.29 17.38-.87 69.969-6.951 127.688-55.521 148.079-120.61 5.141-16.379 7.911-33.821 7.911-51.901 0-3.549-.111-7.069-.321-10.568l-81.24-81.241"
      ></path>
      <path
        fill="#FFF"
        d="M207.32 241.741l-38.89 38.89c-11.111 11.111-29.19 11.111-40.301 0-11.12-11.109-11.12-29.189 0-40.299l38.89-38.892 4.951-4.949a28.307 28.307 0 016.38-4.801 28.2 28.2 0 0110.049-3.289c8.48-1.119 17.369 1.581 23.87 8.09 6.5 6.5 9.2 15.38 8.081 23.86a28.248 28.248 0 0016.44-8.081l4.949-4.949a50.212 50.212 0 00-13.909-26.39 50.21 50.21 0 00-26.401-13.911 50.793 50.793 0 00-30.589 3.821 50.12 50.12 0 00-14.429 10.09L112.57 224.77c-19.691 19.69-19.691 51.731 0 71.42 19.691 19.69 51.729 19.69 71.42 0l43.841-43.839c4.31-4.31 7.669-9.2 10.09-14.431-9.61 4.462-20.349 5.731-30.601 3.821"
      ></path>
      <path
        fill="#C2C2C2"
        d="M237.921 237.921a59.707 59.707 0 01-1.158.52 50.589 50.589 0 01-20.037 4.161h-.127a50.82 50.82 0 01-9.278-.86l-6.24 6.24 15.56 15.56 11.191-11.188a50.2 50.2 0 0010.089-14.433"
      ></path>
      <path
        fill="#8E2E18"
        d="M212.134 196.357l.135.134-.135-.134m-19.903-8.201c7.12.021 14.233 2.696 19.717 8.02-5.484-5.324-12.596-7.999-19.717-8.02m-.086 0h-.02.02"
      ></path>
      <path
        fill="#C2C2C2"
        d="M201.43 167.021l-4.94 4.939a28.329 28.329 0 00-8.09 16.441 28.678 28.678 0 013.726-.246H192.231c7.12.021 14.233 2.696 19.717 8.02l.186.182.135.134c5.535 5.535 8.315 12.796 8.327 20.067a28.658 28.658 0 01-.246 3.793 28.248 28.248 0 0016.44-8.081l4.949-4.949a50.212 50.212 0 00-13.909-26.39 50.212 50.212 0 00-26.4-13.91"
      ></path>
      <path
        fill="#1CA4BA"
        d="M296.19 112.572c-19.689-19.69-51.729-19.69-71.418 0l-43.841 43.839a50.12 50.12 0 00-10.09 14.432c-4.46 9.61-5.73 20.35-3.821 30.598a50.211 50.211 0 0013.911 26.39 50.222 50.222 0 0026.39 13.911l4.949-4.949a28.248 28.248 0 008.081-16.44c-8.48 1.119-17.36-1.581-23.86-8.081-6.509-6.5-9.209-15.39-8.09-23.869a28.329 28.329 0 018.09-16.441l43.841-43.838c11.109-11.111 29.19-11.111 40.299 0 11.111 11.118 11.111 29.199 0 40.31l-38.89 38.89c1.911 10.249.641 20.989-3.819 30.599a50.138 50.138 0 0014.43-10.09l43.839-43.839c19.69-19.693 19.69-51.732-.001-71.422"
      ></path>
      <path
        fill="#FFF"
        d="M237.921 237.921c-9.61 4.461-20.35 5.73-30.6 3.82l4.949-4.949a28.248 28.248 0 008.081-16.44 28.248 28.248 0 0016.44-8.081l4.949-4.949c1.911 10.249.64 20.989-3.819 30.599"
      ></path>
      <path
        fill="#C2C2C2"
        d="M241.74 207.322l-6.545.398c-4.611 4.611-8.875 2.438-14.845 12.632a28.245 28.245 0 01-8.081 16.44l-4.949 4.949c.5 2.979 4.627 5.479 7.749 5.479 7.276 0 19.25-1.5 22.851-9.299a50.782 50.782 0 003.82-30.599"
      ></path>
    </svg>
  );
}

export default AnchorSvg;
